<template>
  <div class="home-page">
    <!-- <div class="topbar"></div> -->
    <div class="topnav">
      <div class="logo">
        <img src="@/assets/images/logo_worth.png" alt="" srcset="" />
      </div>
      <div class="navlist">
        <div
          class="navitem"
          :class="active == 'home' ? 'active' : ''"
          @click="handleClick('home')"
        >
          <div class="navcontent">
            <div>首页</div>
            <span>HOME</span>
          </div>
        </div>
        <div
          class="navitem"
          :class="active == 'application' ? 'active' : ''"
          @click="handleClick('application')"
        >
          <div class="navcontent">
            <div>应用介绍</div>
            <span>APPLICATION</span>
          </div>
        </div>
        <div
          class="navitem"
          :class="active == 'about' ? 'active' : ''"
          @click="handleClick('about')"
        >
          <div class="navcontent">
            <div>关于我们</div>
            <span>ABOUT</span>
          </div>
        </div>
        <div
          class="navitem"
          :class="active == 'idea' ? 'active' : ''"
          @click="handleClick('idea')"
        >
          <div class="navcontent">
            <div>企业理念</div>
            <span>IDEA</span>
          </div>
        </div>
        <div
          class="navitem"
          :class="active == 'summarize' ? 'active' : ''"
          @click="handleClick('summarize')"
        >
          <div class="navcontent">
            <div>产品概述</div>
            <span>SUMMARIZE</span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner home">
      <img
        src="@/assets/images/img_banner.png"
        @click="handleClick('summarize')"
        alt=""
      />
    </div>
    <div class="application">
      <div class="title">
        <img src="@/assets/images/img_title4.png" alt="" />
      </div>
      <div class="content">
        <!-- <img src="@/assets/images/img_cpjs.png" alt=""> -->
      </div>
    </div>
    <div class="about">
      <div class="title">
        <img src="@/assets/images/img_title1.png" alt="" />
      </div>
      <div class="content">
        <div class="left">
          <div class="titpic"></div>
          <div class="text">
            浙江喔刷信息技术有限公司是一家以支付业务为基础、以技术研发为使命的综合性金融服务公司，为用户提供银行卡线下POS收单及其它金融个性增值服务业务，在全国建立了20余个省级分公司。
          </div>
          <!-- <div class="more">MORE>></div> -->
        </div>
        <div class="right">
          <img src="@/assets/images/img_gywm.png" alt="" />
        </div>
      </div>
    </div>
    <div class="idea">
      <div class="title">企业愿景</div>
      <div class="des">
        浙江喔刷信息技术有限公司是一家以支付业务为基础的综合性金融服务公司。通过集成平台，以数据运营，线上线下打通，流量营销方式建立网状式多维渠道销售能力。以资源整合，多元化服务，让商户在原有产业基础上享受增值服务和收益的角度服务于市场。
      </div>
    </div>
    <div class="summarize">
      <div class="title">
        <img src="@/assets/images/img_title2.png" alt="" />
      </div>
      <div class="content">
        <!-- <img src="@/assets/images/img_cpjs.png" alt=""> -->
      </div>
    </div>
    <div class="footer">
      <div class="footer-in w1200 clearfix">
        <div class="address">
          <p>公司地址：浙江省金华市东阳市六石街道木雕小镇广福东街1509号201室</p>
          <p>联系电话：15121056742</p>
        </div>
        <div class="copyright">
          <p>
            Copyright &copy;2023 浙江喔刷信息技术有限公司. All rights reserved.
          </p>
          <p>
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              class="icp"
              target="_blank"
              >浙ICP备18054064号-1</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      active: "home",
    };
  },
  methods: {
    handleClick(type) {
      this.active = type;
      // this.$el
      //   .querySelector(`.${type}`)
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      window.scrollTo(0, this.$el.querySelector(`.${type}`).offsetTop - 97);
    },
  },
};
</script>
<style lang="less" scoped>
.home-page {
  padding-top: calc(99px);
  .topbar {
    width: 100%;
    height: 60px;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 1;
  }
  .topnav {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #000;
    height: 99px;
    padding: 0 370px;
    box-sizing: border-box;
    display: flex;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .navlist {
      display: flex;
      margin-left: 306px;
      .navitem {
        display: flex;
        align-items: center;
        padding: 0 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
        color: #ffffff;
        span {
          font-size: 12px;
          opacity: 0.7;
        }
      }
      .navitem.active {
        background: #db4337;
        color: #ffffff;
      }
    }
  }
  .banner {
    img {
      width: 100%;
      height: 650px;
      cursor: pointer;
    }
  }
  .application {
    padding: 90px 0;
    .title {
      img {
        width: 293px;
        height: 83px;
      }
    }
    .content {
      margin-top: 39px;
      width: 100%;
      height: 1080px;
      background-image: url(../assets/images/img_APP.png);
      background-size: cover;
    }
  }
  .about {
    padding: 70px 370px;
    .title {
      img {
        width: 293px;
        height: 83px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 54px;
      .left {
        width: 536px;
        padding-top: 60px;
        position: relative;
        .titpic {
          width: 50px;
          height: 5px;
          background: #b6864a;
        }
        .text {
          margin-top: 20px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #1f1f1f;
          line-height: 48px;
          opacity: 0.8;
          text-align: left;
        }
        .more {
          width: 100px;
          height: 34px;
          border: 1px solid #434343;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #db001a;
          line-height: 34px;
          position: absolute;
          bottom: 0;
          cursor: pointer;
        }
      }
      .right {
        img {
          width: 550px;
          height: 490px;
        }
      }
    }
  }
  .idea {
    width: 100%;
    height: 500px;
    background: url("../assets/images/img_qyyj.png");
    padding: 107px 370px;
    box-sizing: border-box;
    .title {
      font-size: 40px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: left;
    }
    .des {
      font-size: 20px;
      font-family: MicrosoftYaHeiLight;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      margin-top: 79px;
      opacity: 0.7;
      width: 1001px;
      text-align: left;
    }
  }
  .summarize {
    padding: 142px 370px;
    .title {
      img {
        width: 293px;
        height: 83px;
      }
    }
    .content {
      margin-top: 113px;
      width: 1200px;
      height: 808px;
      background-image: url(../assets/images/img_cpjs.png);
      background-size: cover;
    }
  }
}
.footer {
  height: 138px;
  border-top: 1px solid #dddddd;
  font-size: 12px;
  font-family: hanSansLight;
  font-weight: 400;
  color: #999999;
}
.footer .footer-in {
  line-height: 1.4;
  margin-top: 38px;
  padding: 0 100px;
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}
.footer .footer-in .address {
  float: left;
}
.footer .footer-in .copyright {
  float: right;
  text-align: right;
}
.footer .footer-in p {
  margin-bottom: 20px;
}
.footer .footer-in .icp,
.footer .footer-in .security {
  color: #999999 !important;
  margin-left: 15px;
  line-height: 20px;
}
.footer .footer-in .icp img,
.footer .footer-in .security img {
  display: inline-block;
  vertical-align: -5px;
  margin-right: 5px;
}
</style>
